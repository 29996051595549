import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import SpinnerDotPage from '@components/Spinners/SpinnerDotPage';
import { useAuth } from '@contexts/MainAppContext';

const LoginPage = () => {
  const { isUserLoggedIn, isUserInformationLoading } = useAuth();

  useEffect(() => {
    if (isUserInformationLoading) {
      if (isUserLoggedIn) navigate('/dashboard');
      else navigate('/?showLogin=true');
    }
  }, [isUserInformationLoading, isUserLoggedIn]);

  return <SpinnerDotPage />;
};

export default LoginPage;
