import React from 'react';
import { SpinnerDotted } from 'spinners-react';

const SpinnerDot = () => {
  return (
    <div className="flex h-screen w-screen bg-white fixed z-20">
      <div className="m-auto">
        <SpinnerDotted size={50} thickness={180} speed={100} color="rgba(153, 127, 75, 1)" />
      </div>
    </div>
  );
};

export default SpinnerDot;
